
import { computed, defineComponent, PropType, reactive, ref, watch } from "vue";
import {
  CheckCircleOutlined, ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { Modal } from "ant-design-vue";
import useModel from "@/hooks/useModel";
import { FileItem } from "@/API/types";
import { useStore } from "vuex";
import { downloadFromStream, default as downloadFile } from '@/utils/payment/downloadFile';

import * as API from "@/API/payment";
import {updateNormalDealerTemplate} from "@/API/payment";
import axios from "axios";
import moment from "moment/moment";
import { findCurrentUser } from "@/API/user";



type titleProps = "Risk Dealer" | "Normal Dealer" | "Name/Code Changing";
type StatusType = "start" | "checked" | "imported";
export default defineComponent({
  name: "App",
  props: {
    title: {
      type: String as PropType<titleProps>,
      default: "Risk Dealer",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: String,
    },
    round: {
      type: String,
    },
  },
  setup(props, {emit}) {
    const { state } = useStore();

    const _title = computed(() => {
      return `Upload ${props.title} Name List`;
    });
    const _visible = useModel(props, "visible");
    const handleCancel = () => {
      _visible.value = false;
    };
    // entity 列表
    const entityList = computed(() => {
      const arr = ["MBCL"];
      const _arr = state.user.entityList.map((item: any) => item.entityName);
      return arr.filter((item: any) => _arr.includes(item));
    });
    // 表单
    const autoUpdateForm = reactive({
      bu: []
    });
    const manualUpdateForm = reactive({
      bu: []
    });
    // 上传相关
    const uploadStatus = ref<StatusType>("start");
    const curUploadFile = ref<object>({});
    const uploadFileSize = ref<string>("");
    const curPercent = computed(() => {
      if (
        uploadStatus.value === "imported"
      ) {
        return 100;
      } else {
        return 0;
      }
    });
    // 上传前校验
    const beforeUpload = (file: FileItem) => {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        message.error("Only xlsx format files can be uploaded!");
      }
      const isLt5M = file.size / 1024 / 1024 < state.payment.maxFileSize;
      if (!isLt5M) {
        message.error(
          `The file size should not be larger than ${state.payment.maxFileSize}MB!`
        );
      }
      console.log(isXlsx && isLt5M);
      return isXlsx && isLt5M;
    };
    // 保存文件数据
    const uploadRequest = (source: any) => {
      curUploadFile.value = source.file;
      uploadFileSize.value =
        source.file.size / 1024 > 1024
          ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
          : `${Math.floor(source.file.size / 1024)} KB`;
      uploadStatus.value = "checked";
    };
    const emptyVisible = ref<boolean>(false);
    const emptyTipsText = ref<string>("");
    const handleUpdate = () => {
      // entity         props.entity
      // bu             manualUpdateForm.bu
      // payout Round   props.round
      const params = {
        entity: props.entity,
        bu: autoUpdateForm.bu,
        payoutRound: props.round
      }
      API.updateNormalDealerDpStatus({ params: params }).then((res: any) => {
        if (res.code === '0') {
          handleCancel();
          emit('child-event', { message: 'close' });
        }
      });
    }
    // 导入接口调用
    const downloadTipsText = ref<string>('');
    const fileId = ref('');
    const importFile = () => {
      // entity         props.entity
      // bu             manualUpdateForm.bu
      // payout Round   props.round
      // file           curUploadFile.value
      const formData = new FormData();
      formData.append('payoutRound', props.round ? props.round : '');
      formData.append('entity', props.entity ? props.entity : '');
      formData.append('bu', manualUpdateForm.bu?.length > 0 ? manualUpdateForm.bu : [] as any);
      formData.append('file', curUploadFile.value as Blob);


      axios({
        url: 'ptapi/normal-dealer-readiness-setting/manual-dp-status-process',
        data: formData,
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        if (res.data.data.code === '-2') {
          downloadModalVisible.value = true;
          downloadTipsText.value = res.data.data.message;
          fileId.value = res.data.data.data;

          console.log(downloadTipsText.value + "   ...." + fileId.value);

          uploadStatus.value = 'start';
        } else {
          message.success('upload successfully!');
          uploadStatus.value = 'imported';
          handleCancel();
          emit('child-event', { message: 'close' });
        }
      });

    };

    const handleDownload = async () => {
      const params = {
        url: '/ptapi/normal-dealer-readiness-setting/download-template',
        method: 'get',
      };
      downloadFile(params)
    };
    const handleBrowse = () => {
      uploadStatus.value = "start";
    };
    const downloadModalVisible = ref<boolean>(false);
    const cancelTip = () => {
      downloadModalVisible.value = false;
    };
    const handleCheckedMsgDownload = () => {
      const downloadParams = {
        fileId: fileId.value,
      };
      const params = {
        url: `/ptapi/downloadFile`,
        method: 'get',
        params: downloadParams,
      };
      downloadFile(params).then(
          (res) => {
            downloadModalVisible.value = false;
          }
      );
    };

    const buOptions = ref<any[]>([]);
    findCurrentUser().then((res: any) => {
      buOptions.value = res.buList.filter((ele: any) => {
            return ele.parentId === null;
          }).map((ele: any) => {
            return ele.nameCn;
          });
    })
    
    const handleVisibleChange = (visible: boolean) => {
      autoUpdateForm.bu = [];
      manualUpdateForm.bu = [];
      uploadStatus.value = 'start';
    }

    return {
      _title,
      _visible,
      handleCancel,
      beforeUpload,
      uploadRequest,
      handleUpdate,
      importFile,
      handleDownload,
      handleBrowse,
      curUploadFile,
      uploadFileSize,
      curPercent,
      uploadStatus,
      emptyVisible,
      emptyTipsText,
      autoUpdateForm,
      manualUpdateForm,
      entityList,
      downloadModalVisible,
      cancelTip,
      handleCheckedMsgDownload,
      downloadTipsText,
      buOptions,
      handleVisibleChange
    };
  },
  components: {
    ExclamationCircleOutlined,
    CheckCircleOutlined
  },
});
